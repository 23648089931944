export const appendPctToPrice = (price: number, percentage?: number) => {
  if (
    percentage != null &&
    !Number.isNaN(percentage) &&
    percentage > 0 &&
    percentage < 1
  ) {
    return price + Math.floor(price * percentage * 100) / 100;
  }

  return price;
};
