import {
  Calendar,
  DollarSign,
  HoverPopover,
  Star,
} from '@thrivesports/design-system';
import {
  appendPctToPrice,
  availableDaysOfTheWeekConcise,
  avatarPlaceholder,
  buildDomainURL,
  formatAsCurrency,
  PublicCoach,
  publicPricingAppendPercentage,
} from '@thrivesports/shared';
import React, { useContext, useMemo } from 'react';

import { AppAttrsCtx } from '../context/AppAttrsCtx.js';
import { useClosestLocation } from '../hooks/useClosestLocation.js';
import { allSports } from '../hooks/useDefaultSearchAPI.js';
import { useSearchParams } from '../hooks/useSearchParams.js';

export type CoachCardProps = {
  coach: PublicCoach;
  className?: string;
};

export const CoachCard: React.FC<CoachCardProps> = ({ coach }) => {
  const appAttributes = useContext(AppAttrsCtx);

  const locations = useMemo(
    () => coach.coachLocations?.map((cl) => cl.location),
    [coach.coachLocations],
  );
  const closestLoc = useClosestLocation(locations);

  const { sport: searchedSport } = useSearchParams();
  const hasSportFilter = searchedSport?.length && searchedSport !== allSports;

  const availabilityLabel = useMemo(
    () =>
      coach.availableDaysOfTheWeek != null
        ? availableDaysOfTheWeekConcise(coach.availableDaysOfTheWeek)
        : '',
    [coach.availableDaysOfTheWeek],
  );

  const profileUrl = useMemo(
    () =>
      buildDomainURL(
        appAttributes.datasiteDomain,
        `coaches/${coach.publicSitePath}/`,
      ),
    [appAttributes.datasiteDomain, coach.publicSitePath],
  );

  return (
    <div className="tssm-coach-card">
      <div className="ts-card tssm-coach-card-inner">
        <a href={profileUrl}>
          <div className="tssm-coach-row-avatar-ctn">
            {coach.averageReview > 0 ? (
              <div className="tssm-coach-card-rating">
                <Star />
                <span className="tssm-coach-card-rating-inner">
                  {coach.averageReview > 0 ? coach.averageReview.toFixed(1) : 0}
                  {coach.numReviews > 0 ? (
                    <span className="tssm-coach-card-num-reviews">
                      ({coach.numReviews}
                      {''}
                      {coach.numReviews === 1})
                    </span>
                  ) : null}
                </span>
              </div>
            ) : (
              <div className="tssm-coach-card-rating tssm-coach-card-new">
                <span className="tssm-coach-card-rating-inner">NEW COACH</span>
              </div>
            )}

            <img
              src={coach.photo.url || avatarPlaceholder}
              className="tssm-coach-row-avatar"
            />
          </div>
        </a>
        <div className="tssm-coach-card-content">
          <a href={profileUrl}>
            <div className="tssm-coach-card-row tssm-coach-card-first-row">
              <h4 className="tssm-coach-card-name">
                {coach.name.firstName} {coach.name.lastName?.charAt(0) || ''}.
              </h4>
            </div>
            {closestLoc ? (
              <>
                <div className="tssm-coach-card-row tssm-coach-card-row-location">
                  {closestLoc.city}, {closestLoc.stateProvince}
                </div>
              </>
            ) : null}
            <div className="tssm-coach-card-row tssm-coach-card-row-sports">
              {!hasSportFilter && coach.sports?.length ? (
                <h6 className="tsb-profile-sports ts-section-text-highlight">
                  {coach.sports.length > 1
                    ? `${coach.sports.join(', ')}`
                    : `${coach.sports[0]}`}
                </h6>
              ) : null}
            </div>
            {availabilityLabel.length ? (
              <div className="tssm-coach-card-row tssm-coach-card-row-availability">
                <span className="tssm-coach-card-row-inner">
                  <span className="tssm-coach-card-icon">
                    <Calendar />
                  </span>
                  <h6 className="tssm-coach-card-availability">
                    {availabilityLabel}
                  </h6>
                </span>
              </div>
            ) : null}
          </a>
          <div className="tssm-coach-card-row tssm-coach-card-row-rate">
            <FromRate
              profileUrl={profileUrl}
              rate={coach.rateAggregation?.min}
            />
          </div>
          {coach.profile?.shortBio?.length ? (
            <a href={profileUrl}>
              <div className="tssm-coach-card-row">
                {coach.profile.shortBio}
              </div>
            </a>
          ) : null}
          {!coach.profile?.shortBio?.length && coach.profile?.bio?.length ? (
            <ExpandableBio coach={coach} profileUrl={profileUrl} />
          ) : null}
        </div>
      </div>
    </div>
  );
};

const FromRate: React.FC<{ profileUrl: string; rate?: number }> = ({
  profileUrl,
  rate,
}) => {
  if (rate == null) {
    return null;
  }

  // Everywhere else we show a rate, that rate is adjusted on the server-side (PHP).
  // Here, that is not really possible as this component is populated from Elasticsearch.
  const marketplaceRate = appendPctToPrice(rate, publicPricingAppendPercentage);

  return (
    <a href={profileUrl}>
      <span className="tssm-coach-card-row-inner">
        <span className="tssm-coach-card-icon">
          <DollarSign />
        </span>
        <h6 className="tssm-coach-card-price">
          From{' '}
          {formatAsCurrency(marketplaceRate, {
            trailingZeroDisplay: 'stripIfInteger',
          })}
        </h6>
      </span>
    </a>
  );
};

export type ExpandableBioProps = {
  coach: PublicCoach;
  profileUrl: string;
};

const ExpandableBio: React.FC<ExpandableBioProps> = ({ coach, profileUrl }) => (
  <div className="tssm-coach-card-row tssm-coach-card-row-bio">
    <span className="tssm-coach-card-minibio">
      <a
        href={profileUrl}
        dangerouslySetInnerHTML={{ __html: coach.profile.bio ?? '' }}
      ></a>
    </span>

    <HoverPopover
      content={
        <div className="tssm-coach-card-bio-popover-ctn">
          <span className="tssm-coach-card-pop-tagline text-primary">
            Meet Coach
          </span>
          <h4 className="tssm-coach-card-pop-name m-t-0">
            {coach.name.firstName} {coach.name.lastName}
          </h4>
          <div className="tssm-coach-card-bio-popover">
            <div
              dangerouslySetInnerHTML={{ __html: coach.profile.bio ?? '' }}
            ></div>
            <a
              className="tssm-popover-show-more btn btn-lg btn-outline m-t-20"
              href={profileUrl}
            >
              Read More
            </a>
          </div>
        </div>
      }
    >
      <span className="icon-more-info">
        <i className="fa fa-info-circle" aria-hidden="true"></i>
      </span>
    </HoverPopover>
  </div>
);
